import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserManager } from "oidc-client";
import { hl7Instance } from "../../api/axios";
import oidcConfig from "../../config/oidc-config";
import "./Searchprovider.scss";
import userImg from "../../assets/img/avatar-05.svg";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/custom-loader/CustomLoader";
import { formatPhoneNumber, toTitleCase } from "../../utils/Utils";

const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}

const ProviderDetail = () => {
  const navigate = useNavigate();
  const { practitionerId } = useParams();
  const [providerData, setProviderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isGrid, setIsGrid] = useState(false);

  useEffect(() => {
    const fetchProviderData = async () => {
      try {
        const accessToken = await getAccessToken();
        if (!practitionerId) {
          throw new Error("Practitioner ID is undefined.");
        }

        const response = await hl7Instance.get(
          `/PractitionerRole?practitioner=${practitionerId}&_include=PractitionerRole:location`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (
          response.data &&
          response.data.entry &&
          response.data.entry.length > 0
        ) {
          setProviderData(response.data);
        } else {
          throw new Error("Provider data not found or is incomplete.");
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProviderData();
  }, [practitionerId]);

  if (loading) {
    return <CustomLoader />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="main-content">
      <div className="content-header">
        <button
          type="button"
          className="btn btn-border-primary h-48  d-flex align-items-center"
          onClick={() => navigate(-1)}
        >
          <i className="fh_arrow_left icon-mr"></i>
          <span>Back to provider list</span>
        </button>
      </div>
      <div className="card mb-4">
        <div className="card-body">
          <div className="card-content">
            <div className="provider-detail">
              <div className="row align-items-center">
                <div className="col-12 col-md-6 custom-provider-width">
                  <div className="provider-profile d-flex">
                    <span>
                      <img src={userImg} alt="User"></img>
                    </span>
                    <div className="d-flex flex-column">
                      <h2>
                        {toTitleCase(
                          providerData.entry[0].resource.practitioner.display
                        )}
                      </h2>
                      <small>
                        {providerData.entry[0].resource.specialty[0].text}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-auto mt-4 mt-md-0">
                  <div className="provider-info d-flex">
                    <div className="label-info-main d-flex align-items-center mb-0">
                      <i className="fh_profile"></i>
                      <div>
                        <label>Provider ID</label>
                        <span>{providerData.entry[0].resource.id}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-subheader d-flex align-items-center justify-content-between">
        <h2 className="page-subheader">Office Locations</h2>

        <div>
          <input
            type="radio"
            className="btn-check"
            name="options-outlined"
            id="success-outlined"
            autoComplete="off"
            onClick={() => setIsGrid(() => false)}
          ></input>
          <label
            className={
              "btn px-3 me-3 fs-4 " +
              (!isGrid ? "btn-primary" : "btn-border-primary")
            }
            htmlFor="success-outlined"
          >
            <i className="fh_list"></i>
          </label>

          <input
            type="radio"
            className="btn-check"
            name="options-outlined"
            id="danger-outlined"
            autoComplete="off"
            onClick={() => setIsGrid(() => true)}
          ></input>
          <label
            className={
              "btn px-3 fs-4 " + (isGrid ? "btn-primary" : "btn-border-primary")
            }
            htmlFor="danger-outlined"
          >
            <i className="fh_apps"></i>
          </label>
        </div>
      </div>
      {!isGrid && (
        <>
          {providerData.entry.slice(1).map((location, index) => (
            <div className="card" key={index} style={{ marginBottom: "20px" }}>
              <div className="card-body">
                <div className="card-content">
                  <div className="office-location-main">
                    <div className="row d-flex align-items-start">
                      <div className="col-12 col-md-7 col-xl-9">
                        <div className="row">
                          <div className="label-info-main d-flex align-items-start col-12 col-md-12 col-lg-12 col-xl-6 mb-4 mb-xl-0">
                            <i className="fh_hospital_building"></i>
                            <div>
                              <label>Office Name</label>
                              <span>{toTitleCase(location.resource.name)}</span>
                            </div>
                          </div>
                          <div className="label-info-main d-flex align-items-start col-12 col-md-12 col-lg-12 col-xl-6 mb-4 mb-md-0 flex-column">
                            <i className="fh_location_fill"></i>
                            <div>
                              <label>Address</label>
                              <span className="mb-4">
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                    `${location.resource.address.line.join(
                                      " "
                                    )} ${location.resource.address.city} ${
                                      location.resource.address.state
                                    } ${location.resource.address.postalCode}`
                                  )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {`${toTitleCase(
                                    location.resource.address.line.join(", ")
                                  )}, ${toTitleCase(
                                    location.resource.address.city
                                  )}, ${toTitleCase(
                                    location.resource.address.state
                                  )} ${toTitleCase(
                                    location.resource.address.postalCode
                                  )}`}
                                </a>
                              </span>
                            </div>
                            <div className="label-info-main d-flex align-items-start ms-0">
                              <i className="fh_mobile"></i>
                              <div>
                                <label>Phone</label>
                                <span>
                                  {location.resource.telecom &&
                                  location.resource.telecom.length > 0
                                    ? formatPhoneNumber(location.resource.telecom.find(
                                        (t) => t.system === "phone"
                                      )?.value)
                                    : "Not Available"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col-xl-3">
                        <div className="map list">
                          <iframe
                            title="Location"
                            src={`https://maps.google.com/maps?z=14&output=embed&q=${encodeURIComponent(
                              `${location.resource.address.line.join(", ")}, ${
                                location.resource.address.city
                              }, ${location.resource.address.state} ${
                                location.resource.address.postalCode
                              }`
                            )}`}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {isGrid && (
        <>
          <div className="row">
            {providerData.entry.slice(1).map((location, index) => (
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 d-flex"
                key={index}
              >
                <div className="card w-100" style={{ marginBottom: "20px" }}>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="office-location-main">
                        <div className="column">
                          <div className="label-info-main d-flex align-items-start mb-4">
                            <i className="fh_hospital_building"></i>
                            <div>
                              <label>Office Name</label>
                              <span>{toTitleCase(location.resource.name)}</span>
                            </div>
                          </div>
                          <div className="label-info-main d-flex align-items-start mb-4">
                            <i className="fh_location_fill"></i>
                            <div>
                              <label>Address</label>
                              <span>
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                    `${location.resource.address.line.join(
                                      " "
                                    )} ${location.resource.address.city} ${
                                      location.resource.address.state
                                    } ${location.resource.address.postalCode}`
                                  )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {`${toTitleCase(
                                    location.resource.address.line.join(", ")
                                  )}, ${toTitleCase(
                                    location.resource.address.city
                                  )}, ${toTitleCase(
                                    location.resource.address.state
                                  )} ${toTitleCase(
                                    location.resource.address.postalCode
                                  )}`}
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="label-info-main d-flex align-items-start mb-4">
                            <i className="fh_mobile"></i>
                            <div>
                              <label>Phone</label>
                              <span>
                                {location.resource.telecom &&
                                location.resource.telecom.length > 0
                                  ? location.resource.telecom.find(
                                      (t) => t.system === "phone"
                                    )?.value
                                  : "Not Available"}
                              </span>
                            </div>
                          </div>
                          <div className="map grid">
                            <iframe
                              title="Location"
                              src={`https://maps.google.com/maps?z=14&output=embed&q=${encodeURIComponent(
                                `${location.resource.address.line.join(
                                  ", "
                                )}, ${location.resource.address.city}, ${
                                  location.resource.address.state
                                } ${location.resource.address.postalCode}`
                              )}`}
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default ProviderDetail;