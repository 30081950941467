import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import { ssoInstance } from "../../api/axios";
import "./Changepassword.scss";
import "./../register/signup.scss";
import freedomLogo from "../../assets/img/logo/freedom/logo.svg";
import healthSunLogo from "../../assets/img/logo/healthsun/logo.svg";
import optimumLogo from "../../assets/img/logo/Optimum/logo.svg";
import demoLogo from "../../assets/img/logo/demo/demo-logo.svg";
import securLogo from "../../assets/img/secure-logo.svg"
import ultimateLogo from "../../assets/img/ultimate-logo.svg"
import poweredby from "../../assets/img/powered-by-aaneel.svg";
import { useNavigate } from "react-router-dom";
import { getCurrentYear } from "../../utils/Utils";

const validationSchema = yup.object().shape({
  NewPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must not exceed 20 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain an uppercase letter, lowercase letter, a number, and a special character"
    )
    .required("New password is required"),
  ConfirmPassword: yup
    .string()
    .required("Confirm new password is required")
    .oneOf([yup.ref("NewPassword"), null], "Passwords must match"),
});

function Forgetpassword() {
  const [formData, setFormData] = useState({
    NewPassword: "",
    ConfirmPassword: "",
    UserName: "",
  });
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ type: "", message: "" });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const UserName = queryParams.get("username");
    const code = queryParams.get("code");
    // Decode and replace spaces back with `+`
    const fixedCode = code.replace(/ /g, '+');
    setFormData((prevData) => ({
      ...prevData,
      UserName,
      fixedCode,
    }));
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validationSchema
      .validateAt(name, { ...formData, [name]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      })
      .catch((err) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: err.message }));
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const dataToSend = {
        UserName: formData.UserName,
        Password: formData.NewPassword,
        ConfirmPassword: formData.ConfirmPassword,
        IdentityCode: formData?.fixedCode,
      };

      const response = await ssoInstance.post(
        "/api/user/ResetPassword",
        dataToSend
      );

      if (response.data && !response.data.succeeded && response.data.errors) {
        throw new Error(response.data.errors[0].description);
      }
      setAlert({ type: "success", message: "Password reset successfully!" });
      navigate("/");
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const newErrors = {};
        err.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setErrors(newErrors);
      } else {
        setAlert({
          type: "danger",
          message: err.message || "Failed to reset the password!",
        });
      }
    }
  };

  const [newPasswordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
  const clientName = process.env.REACT_APP_ClientName;

  const getLogoSource = () => {
    switch (clientName) {
      case "freedom":
        return `${freedomLogo}`;
      case "healthsun":
        return `${healthSunLogo}`;
      case "optimum":
        return `${optimumLogo}`;
        case "demo":
        return `${demoLogo}`;
        case "secur":
          return `${securLogo}`;
        case "ultimate":
          return `${ultimateLogo}`;
      default:
        return "";
    }
  };

  const togglePassword = () => {
    setPasswordToggle(!newPasswordToggle);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordToggle(!confirmPasswordToggle);
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center authentication-main">
        <div className="d-flex authentication-card">
          <div className="authentication-right forgot-password">
            <div className="authentication-header d-flex justify-content-sm-between align-items-sm-center flex-column flex-sm-row">
              <div className="d-flex flex-column authentication-title">
                <h1>Reset Password</h1>
                <span>Insert information to reset password</span>
              </div>
              <div className="authentication-logo">
                <img src={getLogoSource()} alt="Freedom Health" />
              </div>
            </div>
            <div className="form-content">
              {alert.message && (
                <div className={`alert alert-${alert.type}`} role="alert">
                  {alert.message}
                </div>
              )}
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
              >
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="UserName" className="d-flex form-label">
                      Username
                    </label>
                    <div className="form-field position-relative">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control h-48"
                          id="UserName"
                          name="UserName"
                          value={formData.UserName}
                          readOnly
                        />
                        <span className="field-icon position-absolute top-50 translate-middle-y">
                          <i className="fh_user_fill"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="NewPassword" className="d-flex form-label">
                      New Password <span>*</span>
                    </label>
                    <div className="form-field position-relative">
                      <div className="position-relative">
                        <input
                          type={newPasswordToggle ? "text" : "password"}
                          className={`form-control h-48 ${
                            errors.NewPassword ? "is-invalid" : ""
                          }`}
                          id="NewPassword"
                          name="NewPassword"
                          value={formData.NewPassword}
                          onChange={handleChange}
                          required
                        />
                        <span
                          className="field-icon position-absolute top-50 translate-middle-y"
                          onClick={togglePassword}
                        >
                          <i
                            className={
                              newPasswordToggle
                                ? "fh_eye_off_line"
                                : "fh_eye_fill"
                            }
                          ></i>
                        </span>
                      </div>
                      <div
                        className={`invalid-feedback ${
                          errors.NewPassword ? "invalid-error" : ""
                        }`}
                      >
                        {errors.NewPassword}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <label
                      htmlFor="ConfirmPassword"
                      className="d-flex form-label"
                    >
                      Confirm New Password <span>*</span>
                    </label>
                    <div className="form-field position-relative">
                      <div className="position-relative">
                        <input
                          type={confirmPasswordToggle ? "text" : "password"}
                          className={`form-control h-48 ${
                            errors.ConfirmPassword ? "is-invalid" : ""
                          }`}
                          id="ConfirmPassword"
                          name="ConfirmPassword"
                          value={formData.ConfirmPassword}
                          onChange={handleChange}
                          required
                        />
                        <span
                          className="field-icon position-absolute top-50 translate-middle-y"
                          onClick={toggleConfirmPassword}
                        >
                          <i
                            className={
                              confirmPasswordToggle
                                ? "fh_eye_off_line"
                                : "fh_eye_fill"
                            }
                          ></i>
                        </span>
                      </div>
                      <div
                        className={`invalid-feedback ${
                          errors.ConfirmPassword ? "invalid-error" : ""
                        }`}
                      >
                        {errors.ConfirmPassword}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 form-action">
                    <div className="row">
                      <div className="col-sm-6 col-12">
                        <button
                          type="submit"
                          className="btn btn-primary w-100 h-48"
                        >
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="authentication-footer d-flex flex-column flex-sm-row justify-content-between align-items-sm-end">
              <p>© {getCurrentYear()} AaNeel Infotech LLC</p>
              <span className="poweredby-image">
                <img src={poweredby} alt="AaNeel PoweredBy" />
              </span>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="modal fade show custom-pop-up about-me-popup"
          role="dialog"
          style={{ display: "block" }}
          data-bs-backdrop="static"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Terms and Conditions</h4>
              </div>
              <div className="modal-body">Content will come here...</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-border-primary me-2"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Forgetpassword;