import { useEffect, useRef } from "react";
import { useAuth } from "../context/auth-context";
import { Link, useNavigate } from "react-router-dom";

const useAutoLogout = () => {
  const { userManager } = useAuth();
  const navigate = useNavigate();
  const logoutTimer = useRef(null); // Using useRef to persist logoutTimer

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimer = () => {
      clearTimeout(logoutTimer.current); // Access current property of logoutTimer
      logoutTimer.current = setTimeout(() => {
        // Log the user out after 5 minutes of inactivity
        navigate("/auth-callback-logout");
      }, 5 * 60 * 1000); // 5 minutes
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimer);
    }

    // Set initial timer
    resetTimer();

    return () => {
      clearTimeout(logoutTimer.current); // Access current property of logoutTimer
      for (let i in events) {
        window.removeEventListener(events[i], resetTimer);
      }
    };
  }, [userManager, navigate]); // Added navigate to dependency array

  return null;
};

export default useAutoLogout;
