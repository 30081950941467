import React, { useState, useEffect, useCallback } from "react";
import { UserManager } from "oidc-client";
import { hl7Instance } from "../../api/axios";
import oidcConfig from "../../config/oidc-config";
import { useAuth } from "../../context/auth-context";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./formulary.scss";
import qs from "qs";

const userManager = new UserManager(oidcConfig);
async function getAccessToken() {
  const user = await userManager.getUser();
  if (!user) {
    throw new Error("User is not authenticated");
  }
  return user.access_token;
}

function SearchFormulary() {
  const [searchQuery, setSearchQuery] = useState("");
  const [formularies, setFormularies] = useState([]);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrls, setPrevUrls] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { CoveragePlanCode } = useAuth();
  const [totalRecords, setTotalRecords] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationUrls, setPaginationUrls] = useState({
    current: "",
    next: "",
    prev: "",
  });
  const initialUrl = "";
  const [urlStack, setUrlStack] = useState([initialUrl]);

  const handleSearch = useCallback(async () => {
    setIsLoading(true);
    try {
      setError(null);
      const accessToken = await getAccessToken();
      const endpoint = searchQuery
        ? `/MedicationKnowledge?DrugPlan=${CoveragePlanCode}&DrugName=${searchQuery}`
        : `/MedicationKnowledge?DrugPlan=${CoveragePlanCode}`;

      const response = await hl7Instance.get(endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      setFormularies(response.data.entry || []);
      setTotalRecords(response.data.total || 0);
      const computedPage = Math.floor(((data.entry?.length || 0) - 1) / 10) + 1;
      setCurrentPage(computedPage);

      const nextLink = data.link.find((l) => l.relation === "next");
      const baseURL = hl7Instance.defaults.baseURL || "";
      const queryString = qs.stringify(searchQuery);
      const fullURL =
        `${baseURL}${endpoint}` + (queryString ? `?${queryString}` : "");

      setUrlStack([fullURL]);
      if (nextLink) {
        setPaginationUrls({
          current: fullURL,
          next: nextLink.url,
          prev: "",
        });

        setPrevUrls(fullURL);
        setNextUrl(nextLink.url);
      }
    } catch (searchError) {
    } finally {
      setIsLoading(false);
    }
  }, [searchQuery, CoveragePlanCode]);

  const fetchNextPage = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const accessToken = await getAccessToken();
      const response = await hl7Instance.get(paginationUrls.next, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");
      setUrlStack((prevUrls) => [...prevUrls, paginationUrls.current]);

      setPaginationUrls({
        current: paginationUrls.next,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 1] || "", 
      });

      setFormularies(response.data.entry || []);
      setCurrentPage((prevPage) => prevPage + 1);
      if (totalRecords === null) {
        setTotalRecords(data.total || 0);
      }
      
    } catch (error) {
      setError(
        "An error occurred while fetching the next page. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPreviousPage = async () => {
    setIsLoading(true);
    try {
      setError(null);
      if (urlStack.length == 1) return;
      const prevUrl = urlStack[urlStack.length - 1];

      const accessToken = await getAccessToken();
      const response = await hl7Instance.get(prevUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => {
        // Remove the last URL
        const newUrls = [...prevUrls];
        newUrls.pop();
        return newUrls;
      });

      setPaginationUrls({
        current: prevUrl,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 2] || "",
      });

      setFormularies(response.data.entry || []);
      setCurrentPage((prevPage) => prevPage - 1);
      if (totalRecords === null) {
        setTotalRecords(data.total || 0);
      }
    } catch (error) {
      setError(
        "An error occurred while fetching the previous page. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleClearForm = () => {
    setSearchQuery("");
    handleSearch();
  };
  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(totalRecords / ITEMS_PER_PAGE);

  return (
    <div className="main-content">
      <div className="content-header">
        <h1 className="page-header">Formulary Directory</h1>
      </div>
      <div className="card">
        <div className="card-body">
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="card-form form-content mt-0">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
                <label htmlFor="searchformula" className="d-flex form-label">
                  Search Formulary
                </label>

                <div className="form-field position-relative">
                  <input
                    id="searchformula"
                    type="text"
                    className="form-control h-48"
                    placeholder="Search for a formulary"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <span className="field-icon position-absolute top-50 translate-middle-y">
                    <i className="fh_search"></i>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
                <button
                  type="button"
                  className="btn btn-primary h-48 me-3"
                  onClick={handleSearch}
                >
                  <span>Search</span>
                </button>
                <button
                  type="button"
                  className="btn btn-border-primary h-48"
                  onClick={handleClearForm}
                >
                  <span>Clear</span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-content">
            <div className="content-subheader d-flex align-items-center">
              <h2 className="page-subheader me-3">Formulary List</h2>
              <span className="total-record">
                [ Total Formulary: {totalRecords} ]
              </span>
            </div>
            <PerfectScrollbar className="table-responsive mb-2">
              <table className="table">
                <thead className="sticky-top">
                  <tr>
                    <th className="min-width-250">Name</th>
                    <th>Strength</th>
                    <th className="min-width-250">Drug Tier</th>
                    <th>Generic or Brand?</th>
                    <th>Quantity/Days</th>
                    <th className="min-width-130">Prior Auth Required?</th>
                    <th className="min-width-130">Step Therapy Required?</th>
                    <th>Quantity Limit?</th>
                    <th>Mail Order?</th>
                    <th className="min-width-250">Category</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  isLoading ? (
                    // Show loading spinner when isLoading is true
                    <tr>
                      <td colSpan={7} className="text-center my-4">
                        <div className="spinner-border" role="status"></div>
                      </td>
                    </tr>
                  ):formularies && formularies.length === 0 ? (
                    // Show no data found message if gridData is empty
                    <tr>
                      <td colSpan={10} className="text-center">
                        No data found
                      </td>
                    </tr>
                  ) :
                  
                  formularies.map((entry) => {
                    const { resource } = entry;
                    const planCode =
                      resource.extension.find(
                        (ext) =>
                          ext.url ===
                          "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-PlanID-extension"
                      )?.valueString || "N/A";
                    const drugCode = resource.code.coding[0].code || "N/A";
                    const drugDescription =
                      resource.code.coding[0].display || "N/A";
                    const isBrand = resource.extension.find(
                      (ext) =>
                        ext.url ===
                        "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-IsBrand-extension"
                    )?.valueBoolean
                      ? "Brand"
                      : "Generic";
                    const priorAuthFlag = resource.extension.find(
                      (ext) =>
                        ext.url ===
                        "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-PriorAuthorization-extension"
                    )?.valueBoolean
                      ? "YES"
                      : "NO";
                    const stepTherapyFlag = resource.extension.find(
                      (ext) =>
                        ext.url ===
                        "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-StepTherapyLimit-extension"
                    )?.valueBoolean
                      ? "YES"
                      : "NO";
                    const quantityLimitFlag = resource.extension.find(
                      (ext) =>
                        ext.url ===
                        "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-QuantityLimit-extension"
                    )?.valueBoolean
                      ? "YES"
                      : "NO";
                    const mailOrderFlag = resource.extension.find(
                      (ext) =>
                        ext.url ===
                        "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-MailorderFlag-extension"
                    )?.valueBoolean
                      ? "YES"
                      : "NO";
                    const strength =
                      resource.code.coding[0].extension?.find(
                        (ext) =>
                          ext.url ===
                          "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-strangth"
                      )?.valueString || "N/A";
                    const drugTier =
                      resource.extension.find(
                        (ext) =>
                          ext.url ===
                          "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-DrugTierID-extension"
                      )?.valueCodeableConcept.coding[0].display || "N/A";
                    const drugCategory =
                      resource.productType?.[0]?.coding.find(
                        (coding) =>
                          coding.system ===
                          "http://hl7.org/fhir/sid/drugcategory"
                      )?.display || "N/A";
                    const quantityDays =
                      resource.productType?.[0]?.coding.find(
                        (coding) =>
                          coding.system === "http://hl7.org/fhir/sid/daysupply"
                      )?.display || "N/A";

                    return (
                      <tr key={resource.id}>
                        <td className="min-width-250">{drugDescription}</td>
                        <td>{strength}</td>
                        <td className="min-width-250">{drugTier}</td>
                        <td>{isBrand}</td>
                        <td>{quantityDays}</td>
                        <td>{priorAuthFlag}</td>
                        <td>{stepTherapyFlag}</td>
                        <td>{quantityLimitFlag}</td>
                        <td>{mailOrderFlag}</td>
                        <td className="min-width-250">{drugCategory}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </PerfectScrollbar>
            <div className="d-flex justify-content-end">
              <nav
                aria-label="Page navigation"
                className="d-flex align-items-center"
              >
               
                {formularies.length>0 &&(
                  <>
                   <span className="pagnination-info me-2">Page</span>
                   <ul className="pagination d-flex mb-0">
                   {currentPage > 1 && (
                     <li className="page-item">
                       <button
                         className="page-link"
                         onClick={fetchPreviousPage}
                         disabled={currentPage === 1}
                       >
                         <i className="fh_arrow_left_line"></i>
                       </button>
                     </li>
                   )}
                   <li className="page-item">
                     <button className="page-link current-page">
                       {currentPage}
                     </button>
                   </li>
                   <li className="page-item">
                     <button
                       className="page-link me-0"
                       onClick={fetchNextPage}
                       disabled={currentPage >= totalPages}
                     >
                       <i className="fh_arrow_right_line"></i>
                     </button>
                   </li>
                 </ul>
                 </>
                )}
               
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchFormulary;