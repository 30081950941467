import {
    createSlice,
    createAsyncThunk,
    isPending,
    isRejected,
  } from "@reduxjs/toolkit";
import { BlueButtonApi } from "../../api/services/BlueButtonService";


  
  const initialState = {
    isLoading: false,
    error: null as string | null,
    blueButtonData:null,
    bluebuttonConfig:null
  };

  export const getBBPatientData = createAsyncThunk(
    "patient/getPayerInformationbyID",
    async (ssoguid: string) => {
      try {
        const response = await BlueButtonApi.GetBlueButtonData(ssoguid);
        return response;
      } catch (error) {
        return error;
      }
    }
  );
  export const getBlueButtonConfigurations = createAsyncThunk(
    "patient/getBlueButtonConfig",
    async () => {
      try {
        const response = await BlueButtonApi.getBlueButtonConfig();
        return response;
      } catch (error) {
        return error;
      }
    }
  );

  export const grantBBAccesss = createAsyncThunk(
    "patient/getPayerInformationbyID",
    async (data: object) => {
      try {
        const response = await BlueButtonApi.grantBlueButtonAccess(data);
        return response;
      } catch (error) {
        return error;
      }
    }
  );
  export const revolkBBAccesss = createAsyncThunk(
    "patient/getPayerInformationbyID",
    async (data: object) => {
      try {
        const response = await BlueButtonApi.revolkeBlueButtonAccess(data);
        return response;
      } catch (error) {
        return error;
      }
    }
  );
  const BlueButtonSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getBBPatientData.fulfilled, (state, action) => {
          state.isLoading = false;
          state.blueButtonData = action.payload;
        })
       .addCase(getBlueButtonConfigurations.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.bluebuttonConfig= action.payload
       })
        .addMatcher(isPending, (state) => {
          state.isLoading = true;
          state.error = null;
        })
        .addMatcher(isRejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message ?? null;
        });
    },
  });

  export default BlueButtonSlice.reducer;