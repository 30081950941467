import React, { useEffect } from "react";

class SidebarToggle extends React.Component {
  componentDidMount() {
    const sidebarToggle = document.body.querySelector("#sidebarToggle");

    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", this.handleSidebarToggle);
      if (localStorage.getItem("sb|sidebar-toggle") === "true") {
        document.body.classList.toggle("sb-sidenav-toggled");
      }
    }
  }

  componentWillUnmount() {
    const sidebarToggle = document.body.querySelector("#sidebarToggle");

    if (sidebarToggle) {
      sidebarToggle.removeEventListener("click", this.handleSidebarToggle);
    }
  }

  handleSidebarToggle = (event) => {
    event.preventDefault();

    document.body.classList.toggle("sb-sidenav-toggled");
    localStorage.setItem(
      "sb|sidebar-toggle",
      document.body.classList.contains("sb-sidenav-toggled")
    );
    if (
      document.getElementById("sidebarToggle").firstChild.className ===
      "fh_nav_close"
    )
      document.getElementById("sidebarToggle").firstChild.className =
        "fh_nav_open";
    else
      document.getElementById("sidebarToggle").firstChild.className =
        "fh_nav_close";

    this.props.toggleSideBar();
  };

  render() {
    return (
      <button
        className="btn btn-link btn-sm order-1 order-lg-0"
        id="sidebarToggle"
        href="#!"
      >
        <i className="fh_nav_close"></i>
      </button>
    );
  }
}

export default SidebarToggle;
