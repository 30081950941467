import "./navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import React, { useEffect, useState } from "react";
import userImg from "../../assets/img/avatar-04.svg";
import userladyImg from "../../assets/img/avatar-female.svg";

const NavBar = () => {
  const { userManager } = useAuth();
  const navigate = useNavigate(); // Use navigate function
  const [zoomLevel] = useState("100");
  const [userName, setUserName] = useState("");
  const [impersonateUserName, setimpersonateUserName] = useState("");
  const [userGender, setUserGender] = useState("Male");
  const zLevel = 10;
  const noTimes = 3;
  const minZLevel = 100 - noTimes * zLevel;
  const maxZLevel = 100 + noTimes * zLevel;
  const [isImpersonating, setIsImpersonating] = useState(false);
  const increase = (e) => {
    e.preventDefault();
    let zoom = parseInt(
      document.body.style.zoom
        .toString()
        .substring(0, document.body.style.zoom.toString().length - 1)
    );
    if (zoom < maxZLevel) {
      document.body.style.zoom = `${zoom + zLevel}%`;

      document.getElementById("zoomLevel").innerText = `${zoom + zLevel}%`;
    }
    showHideZoom();
  };
  const decrease = (e) => {
    e.preventDefault();
    let zoom = parseInt(
      document.body.style.zoom
        .toString()
        .substring(0, document.body.style.zoom.toString().length - 1)
    );
    if (zoom > minZLevel) {
      document.body.style.zoom = `${zoom - zLevel}%`;

      document.getElementById("zoomLevel").innerText = `${zoom - zLevel}%`;
    }
    showHideZoom();
  };
  const resetZoom = () => {
    document.body.style.zoom = `100%`;
    document.getElementById("zoomLevel").innerText = `100%`;
    showHideZoom();
  };
  const TwoFA = process.env.REACT_APP_MEMBER_APP_2FA_URL;
  const showHideZoom = () => {
    document.getElementById("zoomLevel").classList.remove("d-none");
    setTimeout(() => {
      document.getElementById("zoomLevel").classList.add("d-none");
    }, 6000);
  };
  useEffect(() => {
    let zoom = parseInt(
      document.body.style.zoom
        .toString()
        .substring(0, document.body.style.zoom.toString().length - 1)
    );
    document.getElementById("zoomLevel").setAttribute("title", `${zoom}%`);
    async function fetchUserName() {
      try {
        const user = await userManager.getUser();
        if (user) {
          if (user.profile.fullname) {
            setUserName(user.profile.fullname);
          } else {
            setUserName(user.profile.given_name);
          }
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    }

    fetchUserName();
  }, [userManager]);
  const handleLogout = async () => {
    try {
      navigate("/auth-callback-logout");
      localStorage.setItem("logout", Date.now());
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };
  const handleExitImpersonate = () => {
    // Remove specific items from localStorage
    localStorage.removeItem("ImpersonateUserID");
    localStorage.setItem("isImpersonate", "false");
    localStorage.removeItem("PortalAdmin-AccessToken");
    sessionStorage.removeItem("PortalAdmin-AccessToken");
    setIsImpersonating(false);
    // Close the current tab/window
    window.close();
  };
  useEffect(() => {
    // Check if 'isImpersonate' is set to 'true' in localStorage
    const isImpersonate = localStorage.getItem("isImpersonate") === "true";
    setIsImpersonating(isImpersonate);
  }, []);
  const toggleSideBar = () => {
    const sidebarToggle = document.body.querySelector("#sidebarToggle");

    if (sidebarToggle) {
      document.body.classList.toggle("sb-sidenav-toggled");
    }
  };

  return (
    <>
      <nav className="top-bar sb-topnav navbar navbar-expand navbar-light">
        <button
          className="btn btn-link btn-sm toggle-btn d-touch"
          onClick={toggleSideBar}
        >
          <i className="fh_menu_1"></i>
        </button>
        <div className="container-fluid">
          <button
            className="navbar-toggler px-0"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarExample2"
            aria-controls="navbarExample2"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fh_menu_1"></i>
          </button>
          {isImpersonating && (
            <div
              className="toast d-flex  start-50 translate-middle-x position-fixed d-block custom-top-header-alert z-1"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="align-items-center text-center rounded">
                <div className="toast-body justify-between px-3 py-2 rounded">
                  <span className="me-2 position-relative top-1">
                    Impersonate mode is ON, Click here to
                  </span>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="exitImpersonate"
                    onClick={handleExitImpersonate}
                  >
                    Exit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <ul className="navbar-nav">
          <li className="nav-item dropdown align-items-center d-flex">
            <a
              className="nav-link "
              role="button"
              href="#!"
              onClick={decrease}
              aria-expanded="false"
            >
              <i className="fh_subtract"></i>
            </a>
            <a
              className="nav-link flex-column"
              role="button"
              href="#!"
              onClick={resetZoom}
            >
              <i className="fh_zoom"></i>
              <div id="zoomLevel" className="d-none custom-none">
                {zoomLevel}%
              </div>
            </a>
            <a
              className="nav-link"
              onClick={increase}
              role="button"
              href="#!"
              aria-expanded="false"
            >
              <i className="fh_add"></i>
            </a>
          </li>
          <li className="nav-item dropdown img-section align-items-center d-flex">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              href="#!"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="d-flex align-items-center">
                <div className="img-info column d-none d-md-block">
                  <div className="welcome-info">Welcome Back!</div>
                  <div className="user-name">{userName}</div>
                </div>
                <span className="user-img">
                  {userGender === "Male" ? (
                    <img src={userImg} alt="User"></img>
                  ) : (
                    <img src={userladyImg} alt="User"></img>
                  )}
                </span>
              </div>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end header-dropdown"
              aria-labelledby="navbarDropdown"
            >
              <li className="d-block d-md-none">
                <a className="dropdown-item text-capitalize" href="#!">
                  <i className="fh_user absolute"></i>{" "}
                  <span className="ms-3">{userName}</span>
                </a>
              </li>
              <li>
                <Link
                  className="dropdown-item align-items-center d-flex"
                  to="/ChangePassword"
                >
                  <i className="fh_key_fill absolute"></i>
                  <span className="ms-3">Change Password</span>
                </Link>
              </li>
              <li>
                <a
                  className="dropdown-item align-items-center d-flex"
                  href={TwoFA}
                  target="_blank"
                >
                  <i className="fh_key_fill absolute"></i>
                  <span className="ms-3">Enable 2FA</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item align-items-center d-flex"
                  href="#!"
                  onClick={handleLogout}
                >
                  <i className="fh_log_off absolute"></i>{" "}
                  <span className="ms-3">Logout</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;