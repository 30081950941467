import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import "./PostLogout.scss";

const PostLogout = () => {
  const navigate = useNavigate();
  const { userManager } = useAuth();
  useEffect(() => {
    const timer = setTimeout(() => {
      userManager.signinRedirect();
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="position-relative post-logout">
      <svg
        viewBox="0 0 960 480"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        className="min-h-screen min-w-screen fixed left-0"
        preserveAspectRatio="none"
      >
        <rect x="0" y="0" width="960" height="480" fill="#e6ebf0"></rect>
        <path
          d="M0 331L26.7 321C53.3 311 106.7 291 160 291C213.3 291 266.7 311 320 329.5C373.3 348 426.7 365 480 373.2C533.3 381.3 586.7 380.7 640 373.8C693.3 367 746.7 354 800 341.2C853.3 328.3 906.7 315.7 933.3 309.3L960 303L960 541L933.3 541C906.7 541 853.3 541 800 541C746.7 541 693.3 541 640 541C586.7 541 533.3 541 480 541C426.7 541 373.3 541 320 541C266.7 541 213.3 541 160 541C106.7 541 53.3 541 26.7 541L0 541Z"
          strokeLinecap="round"
          strokeLinejoin="miter"
        ></path>
      </svg>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="z-1 text-center">
          <div className="fw-bold logout-txt">Logged Out</div>
          <p className="lh-lg mt-0 fw-semibold text-secondary sub-logout-txt">
            Seems like you've logged out.
            <span className="fw-bold mb-4 ms-1">Redirecting to login</span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default PostLogout;