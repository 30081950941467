import "../custom-loader/CustomLoader.scss";

const CustomLoader = () => {
  return (
    <>
      <div className="custom-loader-info">
        <div className="position-relative">
          <div className="custom-loader d-flex justify-content-center">
            <div className="rotating">
              <i className="fh_settings"></i>
            </div>
          </div>
          <div className="loader-info">
            <span>L</span>
            <span>o</span>
            <span>a</span>
            <span>d</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomLoader;