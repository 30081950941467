import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { UserManager } from "oidc-client";
import { ssoInstance } from "../../api/axios";
import oidcConfig from "../../config/oidc-config";
import "./Changepassword.scss";
const userManager = new UserManager(oidcConfig);

const validationSchema = yup.object().shape({
  OldPassword: yup.string().required("Old password is required"),
  NewPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must not exceed 20 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain an uppercase letter, lowercase letter, a number, and a special character"
    )
    .required("New password is required"),
  ConfirmPassword: yup
    .string()
    .required("Confirm new password is required")
    .oneOf([yup.ref("NewPassword"), null], "Passwords must match"),
});

function ChangePasswordForm() {
  const [formData, setFormData] = useState({
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
    UserID: "",
    UserName: "",
  });
  const [userID, setUserID] = useState(null);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [newPasswordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
  
  useEffect(() => {
    async function loadUserInfo() {
      const user = await userManager.getUser();
      if (user) {
        setUserID(user.profile.given_name);
        setFormData((prevData) => ({
          ...prevData,
          UserID: user.profile.sub,
          UserName: user.profile.given_name,
        }));
      }
    }
    loadUserInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validationSchema
      .validateAt(name, { ...formData, [name]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      })
      .catch((err) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: err.message }));
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const user = await userManager.getUser();
      const headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
      const response = await ssoInstance.post(
        "/api/User/ChangePassword",
        formData,
        { headers }
      );

      if (response.data && !response.data.succeeded && response.data.errors) {
        throw new Error(response.data.errors[0].description);
      }
      setAlert({ type: "success", message: "Password changed successfully!" });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const newErrors = {};
        err.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setErrors(newErrors);
      } else {
        setAlert({
          type: "danger",
          message: err.message || "Failed to change the password!",
        });
      }
    }
  };
  const toggleNewPassword = () => {
    setPasswordToggle(!newPasswordToggle)
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordToggle(!confirmPasswordToggle)
  };
  return (
    <>
      <div className='main-content'>
        <div className='content-header'>
          <h1 className='page-header'>Change Password</h1>
        </div>
        <div className='card'>
          <div className='card-body'>
            {alert.message && (
              <div className={`alert alert-${alert.type}`} role='alert'>
                {alert.message}
              </div>
            )}
            <div className='card-content'>
              <div className='content-subheader'>
                <h2 className='page-subheader'>User ID: {userID}</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='form-content mt-0'>
                  <div className='row'>
                    <div className='col-sm-6 col-md-4 col-xl-4'>
                      <label htmlFor='OldPassword' className='form-label'>
                        Old Password
                      </label>
                      <div className='form-field position-relative'>
                        <input type='password'
                          className={`form-control ${errors.OldPassword ? "is-invalid" : ""}`}
                          id='OldPassword'
                          name='OldPassword'
                          placeholder='Enter your old password'
                          value={formData.OldPassword}
                          onChange={handleChange}
                          required />
                          <div className={`invalid-feedback ${
                              errors.OldPassword
                                ? "invalid-error"
                                : ""
                                }`}>
                                {errors.OldPassword}
                          </div>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-4 col-xl-4'>
                      <label htmlFor='NewPassword' className='form-label'>
                        New Password
                      </label>
                      <div className='form-field position-relative'>
                        <input
                          type={newPasswordToggle ? 'text' : 'password'}
                          className={`form-control ${errors.NewPassword ? "is-invalid" : ""
                            }`}
                          id='NewPassword'
                          name='NewPassword'
                          placeholder='Enter your new password'
                          value={formData.NewPassword}
                          onChange={handleChange}
                          required
                        />
                        <span id="tooglePasswordEvent" className='field-icon position-absolute top-50 translate-middle-y'
                          onClick={toggleNewPassword}>
                          <i className={newPasswordToggle ? 'fh_eye_off_line' : 'fh_eye_fill'}></i>
                        </span>
                      </div>
                      <div className={`invalid-feedback ${
                          errors.NewPassword
                            ? "invalid-error"
                            : ""
                            }`}>
                            {errors.NewPassword}
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-4 col-xl-4'>
                      <label htmlFor='ConfirmPassword' className='form-label'>
                        Confirm New Password
                      </label>
                      <div className='form-field position-relative'>
                        <input
                          type={confirmPasswordToggle ? 'text' : 'password'}
                          className={`form-control ${errors.ConfirmPassword ? "is-invalid" : ""
                            }`}
                          id='ConfirmPassword'
                          name='ConfirmPassword'
                          placeholder='Confirm your new password'
                          value={formData.ConfirmPassword}
                          onChange={handleChange}
                          required
                        />
                        <span className='field-icon position-absolute top-50 translate-middle-y'
                          onClick={toggleConfirmPassword}>
                          <i className={confirmPasswordToggle ? 'fh_eye_off_line' : 'fh_eye_fill'}></i>
                        </span>
                      </div>
                      <div className={`invalid-feedback ${
                          errors.ConfirmPassword
                            ? "invalid-error"
                            : ""
                            }`}>
                            {errors.ConfirmPassword}
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-auto mt-3 text-center'>
                    <button type='submit'
                      className='btn btn-primary col-12 col-md-auto h-48'>
                      Change Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChangePasswordForm;