import { DevEnvironment } from "./dev.env";
import { ProdEnvironment } from "./prod.env";
import { StageEnvironment } from "./staging.env";

export interface Environment {
  base_url: string;
  
}
export function getEnvVariable() {

  switch (process.env.REACT_APP_NODE_ENV ) {
    case "production":
      return ProdEnvironment;
    case "test":
      return StageEnvironment;
    case "staging": // Handle staging environment
      return StageEnvironment;
    default:
      return DevEnvironment;
  }
}
