import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { UserManager } from "oidc-client";
import { hl7Instance } from "../../api/axios";
import oidcConfig from "../../config/oidc-config";
import "./Labresult.scss";
import { useNavigate } from "react-router-dom";
import {
  toTitleCase,
  formatDate,
} from "../../utils/Utils";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
}

const LabDetail = () => {
  const navigate = useNavigate();
  const { labId } = useParams();
  console.log(`This is from URL: ${labId}`);
  const [labData, setLabData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLabData = async () => {
      try {
        const accessToken = await getAccessToken();
        if (!labId) {
          throw new Error("Lab ID is undefined.");
        }

        const response = await hl7Instance.get(`/Observation/${labId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.status < 200 || response.status >= 300) {
          throw new Error(`Server responded with status: ${response.status}`);
        }
        setLabData(response.data);
      } catch (error) {
        console.error("Error fetching lab data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchLabData();
  }, [labId]);

  if (loading) {
    return <p>Loading lab details...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <div className="main-content">
        <div className="content-header">
          <button
            type="button"
            className="btn btn-border-primary h-48  d-flex align-items-center"
            onClick={() => navigate(-1)}
          >
            <i className="fh_arrow_left icon-mr"></i>
            <span>Back to Labs</span>
          </button>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="card-content">
              <div className="content-subheader">
                <h2 className="page-subheader">Lab Details</h2>
              </div>
              {labData && (
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 col-xl-6 mb-4">
                    <div className="label-info-main d-flex align-items-center">
                      <i className="fh_hexagram"></i>
                      <div>
                        <label>Display</label>
                        <span>
                          {toTitleCase(labData.code.coding[0].display)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="label-info-main d-flex align-items-center">
                      <i className="fh_hexagram"></i>
                      <div>
                        <label>Code</label>
                        <span>{labData.code.coding[0].code}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="label-info-main d-flex align-items-center">
                      <i className="fh_event_fill"></i>
                      <div>
                        <label>Effective Date</label>
                        <span>{formatDate(labData.effectiveDateTime)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="label-info-main d-flex align-items-center">
                      <i className="fh_event_fill"></i>
                      <div>
                        <label>Last Updated</label>
                        <span>{formatDate(labData.meta.lastUpdated)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="label-info-main d-flex align-items-center">
                      <i className="fh_hexagram"></i>
                      <div>
                        <label>Resource Type</label>
                        <span>{labData.resourceType}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="label-info-main d-flex align-items-center">
                      <i className="fh_hexagram"></i>
                      <div>
                        <label>Reference Range </label>
                        <span>
                          {labData.referenceRange[0].low.value} -{" "}
                          {labData.referenceRange[0].high.value}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="label-info-main d-flex align-items-center">
                      <i className="fh_hexagram"></i>
                      <div>
                        <label>Value Range</label>
                        <span>{labData.valueQuantity?.value}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="label-info-main d-flex align-items-center">
                      <i className="fh_hexagram"></i>
                      <div>
                        <label>Status</label>
                        <span>{labData.status}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LabDetail;