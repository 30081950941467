import { store } from "../redux/Store";
import { generateUUID } from "../utils/Utils";
const id = generateUUID();
function redirectToPrevInsuranceEndpoint() {
  function getInsuranceData() {
    const state = store.getState();
    return state.insuranceData;
  }
  function createAuthConfig(insuranceData) {
    const dynamicConfig = insuranceData?.payerConfiguration
      ?.filter(item => item.configName === "OIDC")
      .reduce((acc, item) => {
        acc[item.configKey] = item.configType === "Dynamic"?id : item.configValue;
      
        return acc;
      }, {});

    return {
      authorizationEndpoint: insuranceData?.payerInformation?.authorizeURL,
      tokenEndpoint: insuranceData?.payerInformation?.tokenURL,
      ...dynamicConfig,
    };
  }

  const insuranceData = getInsuranceData();
  const prevInsuranceAuthConfig = createAuthConfig(insuranceData);
  const { authorizationEndpoint, tokenEndpoint, redirect_uri, ...dynamicConfig } = prevInsuranceAuthConfig;
  if (!authorizationEndpoint || !redirect_uri) {
    console.error("Missing essential values in authorization config:", {
      authorizationEndpoint,
      redirect_uri,
    });
    return;
  }
  const dynamicQueryParams = Object.entries(dynamicConfig)
    .filter(([key]) => !['authorizationEndpoint', 'tokenEndpoint', 'redirect_uri'].includes(key))
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");

  const url = `${authorizationEndpoint}?${dynamicQueryParams}&redirect_uri=${encodeURIComponent(redirect_uri)}`;
  window.location.href = url;
}

 
function getCodeFromCallbackURL() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("code");
}


export {
  redirectToPrevInsuranceEndpoint,
  getCodeFromCallbackURL,
};
