import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import CustomLoader from "../custom-loader/CustomLoader";

function AuthCallbackSilent() {
  const { userManager } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    userManager.signinSilentCallback().catch((err) => {
      console.error("Silent Renewal Error:", err);
      setError(err.message || "Silent Renewal Error");
    });
  }, [userManager]);
  if (error) {
    return <div>Error: {error}</div>;
  }

  return <CustomLoader />;
}
export default AuthCallbackSilent;