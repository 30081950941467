import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../../config/blueButtonOAuthConfig"; // Import the function for fetching the token
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { dbInstance } from "../../api/axios";
import { useAuth } from "../../context/auth-context";
import CustomLoader from "../custom-loader/CustomLoader";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { grantBBAccesss } from "../../redux/slice/BlueButtonSlice";
import { useDispatch } from "react-redux";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";

const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}

const fetchPayerResourceBatch = async (requestBody) => {
  const apiUrl = process.env.REACT_APP_BB_RETRIVER;

  try {
    const response = await axios.post(apiUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching API:", error);
    throw error;
  }
};
const BlueButtonCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBlueButtonToken } = useAuth();
  const [updatedData, setUpdateData] = useState([]);
  const [show, setShow] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleClose = () => {
    navigate("/");
  };

  useEffect(() => {
    const updateDAta = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const error = urlParams.get("error");
      const errorDescription = urlParams.get("error_description");

      if (code) {
        // Fetch the token using the authorization code
        fetchToken(code)
          .then(async (userData) => {
            // Set the BlueButton access token in the context
            if (userData && userData.access_token) {
              localStorage.setItem(
                "BB_Grant_Expiary",
                userData.access_grant_expiration
              );
              setBlueButtonToken(userData.access_token);
            }
            if (userData.patient && updatedData) {
              const accessToken = await getAccessToken();

              const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
              const userSub = tokenPayload.sub;
              const requestBodyForfetchPayerResourceBatch = {
                PayerMemberID: userData.patient,
                SSOUserID: userSub,
                PayerID: 1,
              };

              const PayloadForGrantAcces = {
                SsoUserId: userSub,
                bbMemberID: userData.patient,
                bbIsActive: "Yes", // Adjusted to match the state value
                bbRefreshToken: userData.refresh_token                 ,
                accessGrantExpiration: userData.access_grant_expiration,
                isDataSync: false,
              };

              await dispatch(grantBBAccesss(PayloadForGrantAcces));
              await fetchPayerResourceBatch(
                requestBodyForfetchPayerResourceBatch
              );
              navigate("/claim", { state: { fromBlueButton: true } });
            }
          })
          .catch((fetchError) => {
            console.error("Error when fetching the token:", fetchError);
          });
      } else if (error) {
        console.error(
          `OAuth Error: ${error}. Description: ${errorDescription}`
        );
        setShow(true);
      } else {
        console.error("Error: Authorization code not found!");
        // navigate("/login");
      }
    };

    updateDAta();
  }, [navigate, updatedData]);

  return (
    <>
      <CustomLoader />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custom-pop-up about-me-popup"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redirection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Request denied by the user,Plesae close the popup to move to home
          page.
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-border-primary me-2"
            onClick={() => {
              setIsCheckboxChecked(false);
              handleClose();
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BlueButtonCallback;
