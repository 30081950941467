import React, { useEffect, useRef } from "react";
import { useAuth } from "../../context/auth-context";
import "./auth-callback-logout.css";
function AuthCallbackLogout() {
  const { userManager } = useAuth();
  const iframeRef = useRef(null);

  useEffect(() => {
    const performSSOLogout = async () => {
      try {
        const user = await userManager.getUser();
        if (!user || !user.id_token) {
          return;
        }

        await userManager.signoutRedirect({
          id_token_hint: user.id_token,
          post_logout_redirect_uri: `${window.location.origin}/auth-callback-logout`,
        });

        iframeRef.current.src = userManager.createSignoutRequest({}).url;
      } catch (error) {}
    };
    performSSOLogout();
  }, [userManager]);

  return (
    <>
      <div className="sb-nav-fixed1">
        <div className="p-5 bg-body rounded shadow-sm bgspecial">
          <main className="form-signin w-100 m-auto">
            <div>
              <div>
                <img
                  src={
                    require("../../assets/img/aaneel-identitiy-service-logo.svg")
                      .default
                  }
                  alt="mySvgImage"
                  width={"70%"}
                  height={"50%"}
                />
              </div>

              <br />
              <h5>
                <b>You have been logged out.</b>
              </h5>
              <br></br>
              <div className="infobar-blue">
                <i className="fa fa-info-circle mrg-r-10"></i>{" "}
                <span>
                  It is strongly recommended to <b>close the browser window.</b>
                </span>
              </div>
            </div>
            <iframe
              title="silent-logout"
              ref={iframeRef}
              style={{ display: "none" }}
            ></iframe>
          </main>
        </div>
      </div>
    </>
  );
}
export default AuthCallbackLogout;